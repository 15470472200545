import React from 'react'
import styled from 'styled-components'
import Carousel from 'react-material-ui-carousel'

import { media } from '../../styles/util'

const Container = styled('div')<{ indicatorCols?: number }>`
  width: 100%;
  margin: 0 auto;
  display: grid;
  place-items: center;

  ${media.mobile} {
    margin-bottom: 60px;
    padding: 0 10px;
  }
  ${media.tablet} {
    margin-bottom: 70px;
    padding: 0 40px;
  }
  ${media.desktop} {
    max-width: 950px;
    padding: 0;
  }

  & .image-carousel {
    height: fit-content;
    width: 100%;

    & .carousel--indicator-container {
      width: fit-content;
      display: grid;
      grid-template-columns: repeat(${props => props.indicatorCols}, 19px);
      place-items: center;
      grid-column-gap: 9.5px;

      ${media.mobile} {
        margin: 40px auto 14px auto;
      }
      ${media.tablet} {
        margin: 52px auto 15px auto;
      }

      & .carousel--indicators {
        height: 19px;
        width: 19px;
        border-radius: 50%;
        padding: 0;

        & svg {
          height: 19px !important;
          width: 19px !important;
        }
      }

      & .carousel--active-indicator {
        color: ${props => props.theme.colors.purple};
        & * {
          stroke: ${props => props.theme.colors.purple};
          stroke-width: 8px;
        }
      }

      & .carousel--inactive-indicator {
        color: transparent;
        border: 1px solid ${props => props.theme.colors.purple} !important;

        & * {
          color: white;
          stroke: white;
          stroke-width: 8px;
        }

        &:hover {
          & * {
            color: ${props => props.theme.colors.purple};
            stroke: ${props => props.theme.colors.purple};
            stroke-width: 8px;
          }
        }
      }
    }

    & .jss5,
    .jss17,
    .Carousel-buttonWrapper-5 {
      height: fit-content;
      top: auto !important;
      bottom: 35px !important;

      & .jss11,
      .jss23,
      .jss12,
      .jss24,
      .Carousel-next-11,
      .Carousel-prev-12 {
        background-color: transparent;
        color: ${props => props.theme.colors.purple} !important;
      }

      & .jss11,
      .jss23,
      .Carousel-next-11 {
        left: auto;

        ${media.mobile} {
          right: 65px;
        }
        ${media.tablet} {
          right: 100px;
        }
        ${media.desktop} {
          right: 300px;
        }
      }
      & .jss12,
      .jss24,
      .Carousel-prev-12 {
        ${media.mobile} {
          left: 65px;
        }
        ${media.tablet} {
          left: 100px;
        }
        ${media.desktop} {
          left: 300px;
        }
      }
    }

    & .jss9,
    .Carousel-buttonVisible-9 {
      padding: 6px;
      opacity: 1;
    }
  }
`

const ImageCarousel: React.FC = ({ children }) => {
  const slideCount = React.Children.toArray(children).length

  return (
    <Container indicatorCols={slideCount}>
      <Carousel
        className="image-carousel"
        animation="slide"
        interval={5000}
        navButtonsAlwaysVisible
        indicatorContainerProps={{ className: 'carousel--indicator-container', style: {} }}
        indicatorProps={{
          className: 'carousel--indicators carousel--inactive-indicator',
          style: {},
        }}
        activeIndicatorProps={{
          className: 'carousel--indicators carousel--active-indicator',
          style: {},
        }}
        fullHeightHover={false}
      >
        {children}
      </Carousel>
    </Container>
  )
}

export default ImageCarousel
